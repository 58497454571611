<template>
  <dashboard-page-wrapper :title="'Calendars'">
    <b-row>
      <b-col md="12">
        <card bodyClasses="p-3 pb-3">
          <base-switch
            v-model="isEnableShortcut"
            offText="Off"
            onText="On"
            @input="toggleCalendar"
          >
          </base-switch>
          <div v-if="isEnableShortcut" class="d-flex align-items-center mt-3">
            <base-button :disabled="calendarShortcuts.length >= availableTemplates || isNewTemplate"
                         type="primary"
                         @click="createCalendarTemplate">
              <i class="ni ni-fat-add"></i>Calendars
            </base-button>
            <div class="ml-3"><b>{{ calendarShortcuts.length }}/{{ availableTemplates }}</b></div>
          </div>
          <b-row>
            <b-col md="6">
              <calendar-list
                v-model="selectedCalendarTemplate"
                :calendarShortcuts="calendarShortcuts"
                :formCalendarBlocked="formCalendarBlocked"
                :isEnableShortcut="isEnableShortcut"
                :unsavedCalendarFormData="unsavedCalendarFormData"
                @save-calendar-confirmation="saveChangesConfirmation"
                @delete-calendar-template="deleteDummyTemplate"
              ></calendar-list>
            </b-col>
            <b-col v-if="isEnableShortcut" class="mt-3" md="6">
              <calendar-form
                :canCreate="canCreate"
                :formCalendarBlocked="formCalendarBlocked"
                :isEnableShortcut="isEnableShortcut"
                :selectedCalendarTemplate="selectedCalendarTemplate"
                @form-calendar-block-change="calendarFormChangeHandler"
                @update-calendar-template="updateCalendarTemplate"
                @enable-new-calendar-template="enableNewTemplate"
              ></calendar-form>
            </b-col>
          </b-row>
        </card>
      </b-col>
    </b-row>
    <confirmation-modal
      ref="calendarOffModal"
      :isClose="true"
      :showClose="false"
      btnClass="justify-content-between"
      cancelBtnText="Keep on"
      confirmBtnText="Confirm turning off"
      confirmBtnType="danger"
      header="Turning off Calendars"
      question="Turning off will remove the button from the extension.<br><br>
        This will not cancel any calendar links that have already been sent."
      @on-accept="turnOffCalendar"
      @on-reject="keepOnCalendar"
    />
    <confirmation-modal
      ref="saveCalendarChangesModal"
      cancelBtnText="Cancel"
      confirmBtnText="Save"
      header="Save changes"
      question="You have made changes to this template, save changes before you leave?"
      @on-accept="saveCalendarTemplateChanges"
      @on-reject="changeCalendarTemplate"
    />
  </dashboard-page-wrapper>
</template>
<script>
import DashboardPageWrapper from '@/components/App/UI/DashboardPageWrapper.vue';
import {mapActions, mapGetters} from 'vuex';
import {
  CREATE_USER_CALENDAR_REQUEST,
  FETCH_USER_CALENDARS_REQUEST,
  UPDATE_TEMPLATE_CALENDAR_REQUEST,
  UPDATE_USER_CALENDAR_REQUEST,
} from '@/store/storeActions';
import CalendarList from './CalendarList.vue';
import CalendarForm from './CalendarForm.vue';
import ConfirmationModal from '@/components/App/modals/ConfirmationModal.vue';

export default {
  components: {
    DashboardPageWrapper,
    CalendarList,
    CalendarForm,
    ConfirmationModal,
  },
  data: function() {
    return {
      availableTemplates: 3,
      calendarTemplates: [],
      isEnableShortcut: false,
      calendarLoadFirst: true,
      formCalendarBlocked: false,
      unsavedCalendarFormData: null,
      selectedCalendarTemplate: null,
      isNewTemplate: false,
    };
  },
  computed: {
    ...mapGetters([
      'userCalendarShortcut',
      'calendarShortcuts',
      'calendarLoaded',
      'userEmail',
    ]),
    canCreate() {
      return this.calendarShortcuts.length < this.availableTemplates;
    },
  },
  watch: {
    userCalendarShortcut: {
      handler: function(val) {
        this.isEnableShortcut = val;
      },
      immediate: true,
    },
    calendarShortcuts: {
      handler: function(value) {
        this.selectedCalendarTemplate = (!!value.length) ?
          (!!this.selectedCalendarTemplate) ?
            (value.find((template) => template.id === this.selectedCalendarTemplate.id)) : value[0] : null;
      },
      immediate: true,
    },
  },
  mounted() {
    !this.calendarLoaded && this.FETCH_USER_CALENDARS_REQUEST();
  },
  methods: {
    ...mapActions([
      UPDATE_USER_CALENDAR_REQUEST,
      FETCH_USER_CALENDARS_REQUEST,
      CREATE_USER_CALENDAR_REQUEST,
      UPDATE_TEMPLATE_CALENDAR_REQUEST,
    ]),
    toggleCalendar: function(value) {
      this.calendarLoadFirst = false;
      if (this.isEnableShortcut) {
        this.updateCalendarShortcut();
      }
    },
    createCalendarTemplate: function() {
      if (this.formCalendarBlocked) {
        this.$refs.saveCalendarChangesModal.openModal(this.unsavedCalendarFormData);
      }
      const payload = {
        name: 'Calendar Title',
        templateUrl: 'Calendar Url',
      };
      this.selectedCalendarTemplate = payload;
      this.canCreate && this.calendarShortcuts.unshift(payload);
      this.isNewTemplate = true;
    },
    turnOffCalendar: function() {
      this.isEnableShortcut = false;
      this.updateCalendarShortcut();
    },
    keepOnCalendar: function() {
      this.isEnableShortcut = true;
      this.updateCalendarShortcut();
    },
    updateCalendarShortcut: function() {
      const payload = {
        calendarShortcut: this.isEnableShortcut,
      };
      this.UPDATE_USER_CALENDAR_REQUEST({payload});
      this.$store.state.auth.user.userCalendarShortcut = this.isEnableShortcut;
    },
    calendarFormChangeHandler: function({blocked, unsavedFormData}) {
      this.formCalendarBlocked = blocked;
      this.unsavedCalendarFormData = unsavedFormData;
    },
    saveCalendarTemplateChanges: function({data, template}) {
      (!!template) && (this.selectedCalendarTemplate = template);
      (!!data && !!data.id) && this.updateCalendarTemplate({
        payload: {
          name: data.name,
          templateUrl: data.templateUrl,
        }, id: data.id,
      });
      (!!data && !data.id) && this.CREATE_USER_CALENDAR_REQUEST({
        payload: {
          name: data.name,
          templateUrl: data.templateUrl,
        },
      });
      this.enableNewTemplate();
    },
    updateCalendarTemplate: function({payload, id}) {
      this.UPDATE_TEMPLATE_CALENDAR_REQUEST({payload, id});
      this.formCalendarBlocked = false;
    },
    changeCalendarTemplate: function({template}) {
      this.selectedCalendarTemplate = template;
    },
    saveChangesConfirmation: function(data) {
      this.$refs.saveCalendarChangesModal.openModal(data);
    },
    deleteDummyTemplate: function() {
      const index = this.calendarShortcuts.findIndex((item) => item.id === undefined);
      if (index !== -1) {
        this.calendarShortcuts.splice(index, 1);
      }
      this.enableNewTemplate();
    },
    enableNewTemplate: function() {
      this.isNewTemplate = false;
      this.formCalendarBlocked = false;
    },
  },
  updated() {
    if (!this.isEnableShortcut && !this.calendarLoadFirst) {
      this.$refs.calendarOffModal.openModal(this.isEnableShortcut);
    }
  },
};
</script>
