<template>
  <div class="card mt-3 p-3 templates-list">
    <template v-if="calendarShortcuts.length > 0">
      <div v-for="calshortcut in calendarShortcuts" :key="calshortcut.id"
           :class="[{'active': !!value && (calshortcut.id === value.id) },{'greyed-out': !isEnableShortcut }]"
           class="app-border p-3 position-relative mb-3 active-element"
           @click="changeTemplate(calshortcut)">
        <div class="mb-2 name"><b>{{ calshortcut.name }}</b></div>
        <div class="font-400 gray-600-color pre-md-text">{{ calshortcut.templateUrl }}</div>
        <div :class="[{'greyed-out': !isEnableShortcut }]"
             class="close-btn"
             @click="tryRemoveTemplate(calshortcut.id)"><img :src="closeImg"/></div>
      </div>
    </template>
    <template v-else>
      <div class="no-templates p-3">
        Fill out the fields and click Save to create your first Calendar
      </div>
    </template>
    <confirmation-modal
      ref="deletionConfirmationModal"
      cancelBtnText="Cancel"
      confirmBtnText="Remove"
      header="Remove calendar?"
      question="Are you sure you want to remove this calendar?"
      @on-accept="removeTemplate"
    />
  </div>
</template>
<script>
import ConfirmationModal from '@/components/App/modals/ConfirmationModal.vue';
import {mapActions} from 'vuex';
import {DELETE_CALENDAR_TEMPLATE_REQUEST} from '@/store/storeActions';

export default {
  components: {
    ConfirmationModal,
  },
  props: {
    calendarShortcuts: Array,
    isEnableShortcut: Boolean,
    formCalendarBlocked: Boolean,
    unsavedCalendarFormData: Object,
    value: Object,
  },
  data: function() {
    return {
      closeImg: 'img/tools/close.svg',
    };
  },
  methods: {
    ...mapActions([
      DELETE_CALENDAR_TEMPLATE_REQUEST,
    ]),
    removeTemplate: function(id) {
      if (id) {
        this.DELETE_CALENDAR_TEMPLATE_REQUEST({id});
      } else {
        this.$emit('delete-calendar-template');
      }
    },
    changeTemplate(template) {
      if (this.isEnableShortcut) {
        if (this.formCalendarBlocked && template.id != this.unsavedCalendarFormData.id) {
          this.$emit('save-calendar-confirmation', {data: {...this.unsavedCalendarFormData}, template});
        } else {
          this.$emit('input', template);
        }
      }
    },
    tryRemoveTemplate: function(id) {
      if (this.$refs.deletionConfirmationModal && this.isEnableShortcut) {
        this.$refs.deletionConfirmationModal.openModal(id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/argon.scss';

.templates-list {
  max-height: 80vh;
  overflow-y: auto;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.no-templates {
  border: 3px dashed $gray-300;
  background: $gray-100;
  color: $gray-500;
  box-sizing: border-box;
  border-radius: 2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1.2px;
}

.active {
  border-color: $primary;

  .name {
    color: $primary;
  }
}

.greyed-out {
  background-color: #DCDCDC;
  cursor: default;
}
</style>
